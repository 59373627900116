<!-- 负责的项目单个详情 -->
<template>
    <div class="project-record-detail-ctn">
        <div class="project-info">
            <div class="form-item">
                <div class="form-item-label">
                    项目名称：
                </div>
                <div class="form-item-value">
                    {{ record.projectName }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    创建时间：
                </div>
                <div class="form-item-value">
                    {{ record.createTime }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    研究目的：
                </div>
                <div class="form-item-value">
                    {{ record.objective }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    预期结果：
                </div>
                <div class="form-item-value">
                    {{ record.result }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    预计起止时间：
                </div>
                <div class="form-item-value">
                   <!--  {{ record.minExperimentCreateTime }} - {{ record.maxExperimentCreateTime }} -->
                   {{ record.time }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    项目参与人：
                </div>
                <div class="form-item-value">
                    {{ record.memberUser }}
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">
                    电子试验记录：
                </div>
                <div class="form-item-value">
                    {{ record.experimentCount }}
                </div>
            </div>
        </div>
        <div class="gap">

        </div>
        <div class="project-chart-ctn">        
            <a-spin :spinning="isChartSpinning" tip="正在加载中">
            <div class="project-chart">
                <div style="width: 60%; min-height: 320px; position: relative; z-index: 100;">
                    <div style="width: 100%; height: 100%;" ref="experimentBarEchartRef">

                    </div>
                    <div style="position: absolute; right: 20px; top: 0;width: 100px; z-index: 1000;">
                        <a-select style="width: 100%;" v-model="timeRage" @change="handleTimeRageChange">
                            <a-select-option v-for="(option, optionIndex) in getDateOption" :key="option.value">
                                {{ option.label  }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div style="width: 35%; margin-left: 20px;" ref="experimentOutPutEchartRef">

                </div>
            </div>
        </a-spin>
    </div>
    </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue-demi';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { getResearchGroupMemberSingleProjectInfo } from '@/api/researchGroup'
import { useResizeObserver } from '@vueuse/core';
//import { } from '@/utils.enum'
import debounce from 'lodash/debounce';
export default {
    props: {
        // 单个项目数据
        record: {
            required: true
        },
        // 课题组ID
        groupId: {
            required: true
        },
        // 用户Id
        userId: {
            required: true
        }
    },
    setup(props, context) {
        const experimentBarEchartRef = ref(); // 项目电子记录增长趋势元素ref
        const experimentBarEchart = ref(null); // 项目电子记录增长趋势echart实例 
        const experimentOutPutEchartRef = ref();    // 电子记录用户输出元素ref
        const experimentOutPutEchart = ref(null);   // 电子记录用户输出echart实例
        onMounted(() => {
            // 一年以前
            const oneYearAgo = new dayjs().subtract(11, 'month');
            const monthList = [];
            for(let i = 0; i < 12; ++i) {
                monthList.push(`${parseInt(oneYearAgo.add(i, 'month').format('MM')) }月`);
            }
            const monthMap = props.record.projecCreateExperimentByMonth.reduce((total, current ) => {
                console.log(current)
                total[`${ parseInt(current.key)}月`] = current.value;
                return total
            }, {})
            
            const barChartData = monthList.map(month => {
                return monthMap[month] ? monthMap[month] : 0;
            })
            initBarChart({
                backgroundColor: '#fff',
                title: {
                    text: '项目电子记录增长趋势',
                    top: '0%',
                    textAlign: 'left',
                    left: '5%',
                    textStyle: {
                        color: '#262626',
                        fontSize: 18,
                        fontWeight: '600',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: '25%',
                    right: '23%'
                },
                grid: {
                    top: '20%',
                    left: '15%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    //data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    data: monthList,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(73,80,87,0.9)',
                        fontSize: 12,
                        fontWeight: 600,
                        interval: 0,
                        padding: [8, 0, 0, 0]
                    },
                }],
                yAxis: [{
                    type: 'value',
                    name: '电子记录（个）',
                    nameLocation: 'center',
                    nameGap: 48,
                    label: {
                        position: 'left',
                    },
                    nameTextStyle: {
                        color: "rgba(73,80,87,0.9)",
                        fontSize: 14,
                        padding: [0, 0, 6, -60],
                        fontWeight: 'bold'
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: 'rgba(73,80,87,0.9)',

                        },

                        padding: 10
                    },
                    minInterval: 1
                }],
                series: [{
                    //name: '电子记录增长趋势',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    barWidth: 20,
                    label: {
                        normal: {
                            show: false,
                            position: "top",
                            formatter: function (data) {
                                return '{a0|' + data.value + '}';
                            },
                            rich: {
                                a0: {
                                    color: '#5B8EF9',
                                    fontSize: 12,
                                    fontFamily: 'DIN',
                                    fontWeight: 'bold'
                                },
                            }
                        },
                    },
                    data: barChartData,
                },]
            })

            const userData = props.record.projectUserCreateExperiment.map(item => {
                return {
                    name: item.key,
                    value: item.value
                }
            })
            const option = {
                title: {
                    text: '电子记录输出'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                //color: ['#405FA7', '#46B5FF'],
                legend: {

                    icon: "circle",
                    top: 'bottom'
                },

                series: [
                    {
                        name: '电子记录输出',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '70%'],
                        label: {
                            position: 'inner',
                            fontSize: 10,
                            formatter: (params) => {
                                return ` ${(params.percent)}%`
                            }
                        },
                        itemStyle: {
                            normal: {
                                /*  color: function (colors) {
                                     var colorList = [
                                         '#405FA7',
                                         '#46B5FF',
 
                                     ];
                                     return colorList[colors.dataIndex];
                                 } */
                            },
                        },
                        labelLine: {
                            show: false,
                            length: 11,
                        },
                        data: userData 
                    },
                ]
            }

            initUserChar(option);

            useResizeObserver(ref(window.document.documentElement), debounce(() => {
                experimentBarEchart.value && experimentBarEchart.value.resize();
                experimentOutPutEchart.value && experimentOutPutEchart.value.resize();
            } , 500))
        })

        const initUserChar = (option) => {
            if (!experimentOutPutEchart.value) {
                experimentOutPutEchart.value = echarts.init(experimentOutPutEchartRef.value);
            }
            experimentOutPutEchart.value.setOption(option)
        }

        const initBarChart = (option) => {
            if (!experimentBarEchart.value) {
                experimentBarEchart.value = echarts.init(experimentBarEchartRef.value);
            }
            experimentBarEchart.value.setOption(option);
        }

        onBeforeUnmount(() => {
            experimentBarEchart.value && experimentBarEchart.value.dispose();
            experimentOutPutEchart.value && experimentOutPutEchart.value.dispose();
        })
        // 获取时间选项
        const getDateOption = computed(() => {
            const currentTime = new dayjs();
            const options = [
                {
                    label: '近一年',
                    //value: `${currentTime.subtract(1, 'year').startOf('month').format('YYYY-MM-DD HH:mm:ss')} - ${currentTime.endOf('month').format('YYYY-MM-DD HH:mm:ss')}`
                    value: 0
                },
                {
                    label: '近半年',
                    //value: `${currentTime.subtract(6, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss') } - ${currentTime.endOf('month').format('YYYY-MM-DD HH:mm:ss')}`
                    value: 1
                }
            ]
            if(props.record.createTime) {
                const createTime = (new dayjs(props.record.createTime));
                for(let i = parseInt((currentTime.startOf('year').format('YYYY'))); i >=  parseInt(createTime.startOf('year').format('YYYY')); --i) {
                    options.push({
                        label: `${i}`,
                        value: `${i}`
                    })
                }
            }
            return options;
           
        })

        const timeRage = ref(0);    // 图表时间区间

        const isChartSpinning = ref(false); // 图标是否正在加载

        // 图表时间区间改变
        const handleTimeRageChange = async (val) => {
            //isChartSpinning.value = true;
            experimentBarEchart.value && experimentBarEchart.value.showLoading({
                text: '正在加载'
            });
            experimentOutPutEchart.value && experimentOutPutEchart.value.showLoading({
                text: '正在加载'
            });
            let startTime = null;
            let endTime = (new dayjs()).endOf('month')
            //.format('YYYY-MM-DD HH:mm:ss')
            let timeGap = 12;   // 时间月间隔
            if(val == 0) {
                //startTime = new dayjs().subtract(1, 'year');
                startTime = new dayjs().subtract(11, 'month');
                //endTime = 
            }
            // 获取近半年
            else if(val == 1)  {
                startTime =  new dayjs().subtract(6, 'month').startOf('month');
                timeGap = 7;
            }
            // 获取某一年
            else {
                startTime = new dayjs(val).startOf('year');
                endTime = new dayjs(val).endOf('year');
            }
            console.log({
                startTime,
                endTime
            })
            //const oneYearAgo = new dayjs().subtract(1, 'year');
            const res = await getResearchGroupMemberSingleProjectInfo({
                startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
                endTime: endTime.format('YYYY-MM-DD HH:mm:ss'),
                projectId: props.record.projectId,
                groupid: props.groupId,
                userid: props.userId
            })
            if(res.success) {
                
            }
            else {
                experimentBarEchart.value && experimentBarEchart.value.hideLoading();
                experimentOutPutEchart.value && experimentOutPutEchart.value.hideLoading();
                return 
            }
            const monthList = [];
            for(let i = 0; i < timeGap; ++i) {
                monthList.push(`${parseInt(startTime.add(i, 'month').format('MM')) }月`);
            }
            const monthMap = res.data.monthlst.reduce((total, current ) => {
                total[`${ parseInt(current.key)}月`] = current.value;
                return total
            }, {})
            
            const barChartData = monthList.map(month => {
                return monthMap[month] ? monthMap[month] : 0;
            })
            initBarChart({
                backgroundColor: '#fff',
                title: {
                    text: '项目电子记录增长趋势',
                    top: '0%',
                    textAlign: 'left',
                    left: '5%',
                    textStyle: {
                        color: '#262626',
                        fontSize: 18,
                        fontWeight: '600',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: '25%',
                    right: '23%'
                },
                grid: {
                    top: '20%',
                    left: '15%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    //data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    data: monthList,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(73,80,87,0.9)',
                        fontSize: 12,
                        fontWeight: 600,
                        interval: 0,
                        padding: [8, 0, 0, 0]
                    },
                }],
                yAxis: [{
                    type: 'value',
                    name: '电子记录（个）',
                    nameLocation: 'center',
                    nameGap: 48,
                    label: {
                        position: 'left',
                    },
                    nameTextStyle: {
                        color: "rgba(73,80,87,0.9)",
                        fontSize: 14,
                        padding: [0, 0, 6, -60],
                        fontWeight: 'bold'
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: 'rgba(73,80,87,0.9)',

                        },

                        padding: 10
                    },
                    minInterval: 1
                }],
                series: [{
                    //name: '电子记录增长趋势',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    barWidth: 20,
                    label: {
                        normal: {
                            show: false,
                            position: "top",
                            formatter: function (data) {
                                return '{a0|' + data.value + '}';
                            },
                            rich: {
                                a0: {
                                    color: '#5B8EF9',
                                    fontSize: 12,
                                    fontFamily: 'DIN',
                                    fontWeight: 'bold'
                                },
                            }
                        },
                    },
                    data: barChartData,
                },]
            })

            const userData = res.data.createlst.map(item => {
                return {
                    name: item.key,
                    value: item.value
                }
            })
            const option = {
                title: {
                    text: '电子记录输出'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                //color: ['#405FA7', '#46B5FF'],
                legend: {

                    icon: "circle",
                    top: 'bottom'
                },

                series: [
                    {
                        name: '电子记录输出',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '70%'],
                        label: {
                            position: 'inner',
                            fontSize: 10,
                            formatter: (params) => {
                                return ` ${(params.percent)}%`
                            }
                        },
                        itemStyle: {
                            normal: {
                                /*  color: function (colors) {
                                     var colorList = [
                                         '#405FA7',
                                         '#46B5FF',
 
                                     ];
                                     return colorList[colors.dataIndex];
                                 } */
                            },
                        },
                        labelLine: {
                            show: false,
                            length: 11,
                        },
                        data: userData 
                    },
                ]
            }

            initUserChar(option);
            isChartSpinning.value = false;
            experimentBarEchart.value && experimentBarEchart.value.hideLoading();
            experimentOutPutEchart.value && experimentOutPutEchart.value.hideLoading();
        }

        // 观察宽度改变事件


        return {
            experimentBarEchartRef,
            experimentBarEchart,
            experimentOutPutEchartRef,
            experimentOutPutEchart,
            getDateOption,
            timeRage,
            handleTimeRageChange,
            isChartSpinning
        }
    }
}
</script>

<style lang="less" scoped>
.project-record-detail-ctn {
    background: #fff;
    padding: 24px 32px;
    display: flex;
    align-items: center;
    box-shadow: @srims-primary-box-shadow;
	border-radius: 4px;
    .project-info {
        flex: 1;
        width: 40%;

        .form-item {
            display: flex;

            .form-item-label {
                color: rgba(0, 0, 0, 0.85);
                width: 100px;
                text-align: left;
            }

            .form-item-value {
                color: rgba(0, 0, 0, 0.65);
                flex: 1;
                width: 0px;
                text-align: left;
            }
        }

        .form-item+.form-item {
            margin-top: 12px;
        }
    }
    .project-chart-ctn {
        flex: 2;
        width: 40%;
    }

    .project-chart {
      
        display: flex;
        height: 100%;
        min-height: 330px;
    }

    .gap {
        width: 1px;
        background: #eee;
        //height: ;
        min-height: 310px;
        margin-left: 40px;
    }
}
</style>