<!-- 个人负责项目页面 -->
<template>
    <div class="user-charge-project">
        <div class="user-charge-project-header">
            <div v-if="userName">
                {{ userName }}负责的项目
            </div>
        </div>
        <a-spin :spinning="loading">
            <div class="projecet-list">
                <div class="projecet-list-item" v-for="(project,index) in list" :key="project.projectId">
                    <ProjectRecordDetail :record="project" :groupId="researchGroupId" :userId="userId"></ProjectRecordDetail>
                </div>
            </div>
        </a-spin>
        <div class="default-pagination" style="padding: 32px 16px 30px 16px;">
			<a-pagination  hideOnSinglePage @change="handlePageChange" v-model="current"
				:pageSize.sync="pageSize" :total="total" :pageSizeOptions="['8' , '12', '24', '48']" showSizeChanger @showSizeChange="handleSizeChange"/>
		</div>
    </div>
</template>


<script>
import { ref, onBeforeMount } from 'vue-demi';
import ProjectRecordDetail from './components/projectRecordDetail.vue';
import { getResearchGroupMemberProjectInfoList } from '@/api/researchGroup';
import dayjs from 'dayjs';
export default {
    props: {
        
    },
    components: {
        ProjectRecordDetail
    },
    setup(props, context) {
        const { $route, $router } = context.root;
    
        const userName = ref(''); // 用户名

        const userId = ref('');    // 用户ID

        const researchGroupId = ref(''); // 课题组id

        const pageSize = ref(8);    // 单页数量
        
        const current = ref(1); // 当前页数

        const total = ref(0);   // 全部条数

        const loading = ref(true);  // 是否正在加载

        const list = ref([]);    // 项目记录本列表数据

        // 页脚改变事件
        const handlePageChange = () => {
            getList();
        }

        // 每页数量改变
        const handleSizeChange = (newCurrent, newPageSize) => {
            current.value = 1;
            pageSize.value =newPageSize;
            getList()
        }

        // 获取列表
        const getList = async () => {
            loading.value = false;
            console.log({
                startTime: (new dayjs()).subtract(1, 'year').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                endTime: (new dayjs()).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
            })
            const res = await  getResearchGroupMemberProjectInfoList({
                startTime: (new dayjs()).subtract(11, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                endTime: (new dayjs()).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
                pageSize: pageSize.value,
                pageNo: current.value,
                groupId: researchGroupId.value,
                userId: userId.value
            });
            if(res.success) {
                list.value = res.data.data;
                total.value = res.data.count;
            }
           /*  const res = await getGroupNotebooks({
                researchGroupId: researchGroupId.value,
                userId: userId.value,
                pageSize: pageSize.value,
                pageNo: current.value
            });
            if(res.success) {
                list.value = res.data
            } */
            loading.value = false;
        }
        

        onBeforeMount(() => {
            userName.value = $route.query.name;
            userId.value = $route.query.id;
            researchGroupId.value = $route.query.researchGroupId;
            getList();
        })

        return {
            userName,
            userId,
            pageSize,
            current,
            total,
            handlePageChange,
            loading,
            list,
            researchGroupId,
            handleSizeChange 
        }
    }
}
</script>

<style lang="less" scoped>
.user-charge-project {
    margin: 20px;
    .user-charge-project-header {
        margin: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        font-weight: bold;
        text-align: left;
    }
    .projecet-list {
        .projecet-list-item {

        }
        .projecet-list-item + .projecet-list-item {
            margin-top: 20px;
        }
    }
}
</style>